import React from "react";
import Slider from "react-slick";
import "../room.css";
import { useTranslation, Trans } from "react-i18next";
import im1 from "../images/suite_duplex/1.jpg";
import im2 from "../images/suite_duplex/2.jpeg";
import im3 from "../images/suite_duplex/3.jpg";
import im4 from "../images/suite_duplex/4.jpeg";
import im5 from "../images/suite_duplex/5.jpeg";
import im6 from "../images/suite_duplex/6.jpeg";
import Booking from "../../Booking/Booking";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { isMobileOnly, isTablet } from "react-device-detect";

var imgs = [im1, im2, im3, im4, im5, im6];

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 10000,
  cssEase: "linear",
};

export function SuiteDuplex() {
  const { t } = useTranslation();

  return (
    <div className="flex-1 overflow-y-scroll roomBody">
      <div className="roomBlock">
        <div className="roomLeftBlock">
          <Slider {...settings}>
            {imgs.map((im, index) => (
              <div className="roomImageBlock" key={index}>
                <img className="header-image-room" alt={index} src={im} />
              </div>
            ))}
          </Slider>
        </div>
        {(!isMobileOnly && !isTablet) && (
          <div className="roomContentBlock">
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              animatePreScroll={false}
              duration={2.5}
            >
              <div className="roomContent roomContentLeft">
                <AnimationOnScroll
                  animateIn="bounceInUp"
                  animateOnce={true}
                  animatePreScroll={false}
                  duration={2}
                >
                  <h3>{t("rooms.duplex_page.title")}</h3>
                </AnimationOnScroll>
                <p>
                  <AnimationOnScroll
                    animateIn="bounceInUp"
                    animateOnce={true}
                    animatePreScroll={false}
                    duration={3}
                  >
                    <Trans
                      i18nKey="rooms.duplex_page.description"
                      components={{ br: <br /> }}
                    />
                  </AnimationOnScroll>
                </p>
                <AnimationOnScroll
                  animateIn="bounceInUp"
                  animateOnce={true}
                  animatePreScroll={false}
                  duration={3.2}
                >
                  <div className="roomContentBottom">
                    <p>{t("rooms.options.spacious_shower")}</p>
                    <p>{t("rooms.options.living_room")}</p>
                    <p>{t("rooms.options.free_wifi")}</p>
                    <p>{t("rooms.options.private_terrace")}</p>
                    <p>{t("rooms.options.garden")}</p>
                  </div>
                </AnimationOnScroll>
              </div>
            </AnimationOnScroll>
          </div>
        )}

        {isTablet && (
          <div className="roomContentBlock">
            <div className="roomContent roomContentLeft">
              <h3>{t("rooms.duplex_page.title")}</h3>
              <p>
                <Trans
                  i18nKey="rooms.duplex_page.description"
                  components={{ br: <br /> }}
                />
              </p>
              <div className="roomContentBottom">
                <p>{t("rooms.options.spacious_shower")}</p>
                <p>{t("rooms.options.living_room")}</p>
                <p>{t("rooms.options.free_wifi")}</p>
                <p>{t("rooms.options.private_terrace")}</p>
                <p>{t("rooms.options.garden")}</p>
              </div>
            </div>
          </div>
        )}

        {isMobileOnly && (
          <div className="roomContentBlock">
            <div className="roomContent roomContentLeft">
              <h3>{t("rooms.duplex_page.title")}</h3>
              <p>
                <Trans
                  i18nKey="rooms.duplex_page.description"
                  components={{ br: <br /> }}
                />
              </p>
              <div className="roomContentBottom">
                <p>{t("rooms.options.spacious_shower")}</p>
                <p>{t("rooms.options.living_room")}</p>
                <p>{t("rooms.options.free_wifi")}</p>
                <p>{t("rooms.options.private_terrace")}</p>
                <p>{t("rooms.options.garden")}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Booking />
    </div>
  );
}

export default SuiteDuplex;
