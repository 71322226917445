import React from "react";
import Slider from "react-slick";
import "./gastro.css";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import m1 from "./images/m1.png";
import m3 from "./images/m3.png";
import m4 from "./images/m4.png";
import m5 from "./images/m5.png";
import m6 from "./images/m6.png";

import ma1 from "./images/ma1.png";
import ma2 from "./images/ma2.png";
import ma3 from "./images/ma3.png";
import ma4 from "./images/ma4.png";
import ma5 from "./images/ma5.png";
import ma6 from "./images/ma6.png";

import gastroMob from "./images/gastronomy_mob.png";

import menuFile from "./menu.pdf";
import Booking from "../Booking/Booking";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { deviceType } from "react-device-detect";

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: ["mobile", "tablet"].includes(deviceType) ? 1 : 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 10000,
  cssEase: "linear",
};

var imagesGastro = [m1, m3, m4, m5, m6, m1, m3, m4, m5, m6];

var imagesGastroMenu = [ma1, ma2, ma3, ma4, ma5, ma6];

var imagesGastroMenuMobile = [ma4, ma5, ma6];

export function Gastro() {
  const { t } = useTranslation();

  return (
    <div className="flex-1 overflow-y-scroll gastroBody">
      <div className="gastroDesciptionContent">
        <h1 className="gastroDesciptionTitle">
          <Trans
            i18nKey="gastro.title"
            defaults="<span>T</span>he <span>t</span>aste <span>o</span>f <span>L</span>os <span>P</span>ilares <span>d</span>e <span>R</span>onda"
            components={{ span: <span /> }}
          />
        </h1>
        <p>{t("gastro.description")}</p>
        {["mobile", "tablet"].includes(deviceType) && (
          <div className="gastroMenuLinksBlock">
            <div className="openingBlock">
              <p className="openingBlockTitle">{t("gastro.menu.opening")}:</p>
              <p>{t("gastro.menu.breakfast")}</p>
              <p>{t("gastro.menu.dinner")}</p>
            </div>
          </div>
        )}
      </div>
      {["mobile", "tablet"].includes(deviceType) && (
        <div className="gastroContent">
          <img alt="gastro" src={gastroMob} />
        </div>
      )}
      {!["mobile", "tablet"].includes(deviceType) && (
        <div className="gastroContent">
          <Slider {...settings}>
            {imagesGastro.map((image, index) => (
              <div className="imageBlock" key={index}>
                <img className="header-image" alt={index} src={image} />
              </div>
            ))}
          </Slider>
        </div>
      )}
      {!["mobile", "tablet"].includes(deviceType) && (
        <div className="gastroMenuContent">
          <div className="gastroMenuImageBlock">
            {imagesGastroMenu.map((image, index) => (
              <div className="imageBlockMenu" key={index}>
                <img className="header-image-menu" alt={index} src={image} />
              </div>
            ))}
          </div>
          <div className="gastroMenuContentBlock">
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              duration={2.5}
            >
              <h1 className="gastroMenuDesciptionTitle">
                {t("gastro.menu.title")}
              </h1>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              duration={3}
            >
              <p className="">{t("gastro.menu.description")}</p>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              duration={3}
            >
              <div className="gastroMenuLinksBlock">
                <NavLink to={menuFile} target="_blank">
                  {t("gastro.menu.menu")}
                </NavLink>
                <div className="openingBlock">
                  <p className="openingBlockTitle">
                    {t("gastro.menu.opening")}:
                  </p>
                  <p>{t("gastro.menu.breakfast")}</p>
                  <p>{t("gastro.menu.dinner")}</p>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      )}

      {["mobile", "tablet"].includes(deviceType) && (
        <div className="gastroMenuContent">
          <div className="gastroMenuContentBlock">
            <h1 className="gastroMenuDesciptionTitle">
              {t("gastro.menu.title")}
            </h1>
            <p className="">{t("gastro.menu.description")}</p>
            <div className="gastroMenuLinksBlock">
              <NavLink to={menuFile} target="_blank">
                {t("gastro.menu.menu")}
              </NavLink>
            </div>
          </div>
          <div className="gastroMenuImageBlock">
            {imagesGastroMenuMobile.map((image, index) => (
              <div className="imageBlockMenu" key={index}>
                <img className="header-image-menu" alt={index} src={image} />
              </div>
            ))}
          </div>
        </div>
      )}
      <Booking />
    </div>
  );
}
export default Gastro;
