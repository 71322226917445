import React from "react";
import { NavLink } from "react-router-dom";
import './footer.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
// import { faTripadvisor } from "@fortawesome/free-brands-svg-icons";
import { faMapLocationDot, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useTranslation, Trans } from 'react-i18next';
import { deviceType } from 'react-device-detect';

export function Footer() {

  const { t, i18n } = useTranslation();
  
  const handleClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
  }

  // const sendEmail = () => { 
  //   window.open('mailto:daryna@lospilaresderonda.com');
  // }

  return (
    // <footer className="w-full text-center border-t border-grey sticky bottom-0">
    <footer className="w-full">
      <div className="footerContent">
        <div className="map">
          <div className="mapContent">
            <iframe title="map" width={['mobile', 'tablet'].includes(deviceType) ? 120 : 250} height={['mobile', 'tablet'].includes(deviceType) ? 120 : 250} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=250&amp;height=250&amp;hl=en&amp;q=Los%20Pilares%20De%20Ronda+()&amp;t=k&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              <a href="https://www.gps.ie/wearable-gps/">wearable trackers</a>
            </iframe>
          </div>
        </div>
        <div className="address">
          <h3 className="titleFooter leftTitleFooter">
            <Trans i18nKey="footer.contacts" defaults="<span>C</span>ontact <span>i</span>nformation" components={{ span: <span /> }} />
          </h3>
          {!['mobile', 'tablet'].includes(deviceType) &&
            <div className="adressField">
              <div className="adressLine">
                <FontAwesomeIcon icon={faMapLocationDot} className='iconAddress' />
                <p>{t('footer.addres1')}<br />{t('footer.addres2')}</p>
              </div>
              <div className="adressLine"><FontAwesomeIcon icon={faPhone} className='iconAddress' /><p>{t('footer.phone')}</p></div>
              <div className="adressLine"><FontAwesomeIcon icon={faEnvelope} className='iconAddress' /><p><a href="mailto:recepcion@lospilaresderonda.com" target="_blank">{t('footer.email')}</a></p></div>
              <div className="adressLine"><FontAwesomeIcon icon={faInstagram} className='iconAddress' /><p className="selectedLink" onClick={() => handleClick('https://www.instagram.com/lospilaresderonda/')}>{t('footer.instagram')}</p></div>
            </div>
          }
          {['mobile', 'tablet'].includes(deviceType) &&
            <div className="adressField">
              <div className="adressLine">
                <p>{t('footer.addres1')}<br />{t('footer.addres2')}</p>
              </div>
              <div className="adressLine"><p>{t('footer.phone')}</p></div>
            </div>
          }
        </div>
        <div className="social">
          <h3 className="titleFooter">
          {!['mobile', 'tablet'].includes(deviceType) && <Trans i18nKey="footer.follow" defaults="<span>F</span>ollow <span>U</span>s" components={{ span: <span /> }} />}
          </h3>
          <FontAwesomeIcon icon={faInstagram} className='iconStyle' onClick={() => handleClick('https://www.instagram.com/lospilaresderonda/')}/>
          <FontAwesomeIcon icon={faFacebook} className='iconStyle' onClick={() => handleClick('https://www.facebook.com/lospilaresderonda')}/>
          {/* <FontAwesomeIcon icon={faTtripadvisor} className='iconStyle'/> */}
        </div>
        {!['mobile', 'tablet'].includes(deviceType) &&
          <div className="weather">
            <h3 className="titleFooter">
              <Trans i18nKey="footer.weather" defaults="<span>W</span>EATHER" components={{ span: <span /> }} />

            </h3>
            <a className="weatherwidget-io" href={"https://forecast7.com/" + (i18n.language === 'es' ? 'es' : "en") + "/36d75n5d16/ronda/"} data-label_1="RONDA" data-label_2="WEATHER" data-days="3" >RONDA WEATHER</a>
            {
              !function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                // if (!d.getElementById(id)) { 
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://weatherwidget.io/js/widget.min.js';
                fjs.parentNode.insertBefore(js, fjs);
                // }
              }
              (document, 'script', 'weatherwRouteidget-io-js')
            }
          </div>
        }
      </div>
      {!['mobile', 'tablet'].includes(deviceType) &&
        <div className="footerContentBottom">
          <NavLink to={"/terms_and_conditions"}>{t('footer.terms')}</NavLink> / <NavLink to={"/privacy_policy"}>{t('footer.privacy')}</NavLink>
          <br />{t('footer.hotel')}
          <br />{t('footer.year')}
        </div>
      }

      {['mobile', 'tablet'].includes(deviceType) &&
        <div className="footerContentBottom">
          <a href="mailto:recepcion@lospilaresderonda.com" target="_blank">{t('footer.email')}</a>
        </div>
      }
    </footer>
  );
}

export default Footer;