import React from "react";
import Slider from "react-slick";
import "./gallery.css";

import img00 from './images/0.jpg';
import img01 from './images/1.jpeg';
import img02 from './images/2.jpeg';
import img03 from './images/3.jpg';
import img04 from './images/4.jpeg';
import img05 from './images/5.jpg';
import img06 from './images/6.jpg';
import img07 from './images/7.jpeg';
import img08 from './images/8.jpeg';
import img09 from './images/9.jpeg';
import img10 from './images/10.jpeg';
import img11 from './images/11.jpeg';
import img12 from './images/12.jpeg';
import img13 from './images/13.jpg';
import img14 from './images/14.jpg';
import img15 from './images/15.jpeg';
import img16 from './images/16.jpg';
import img17 from './images/17.jpeg';
import img18 from './images/18.jpeg';
import img19 from './images/19.jpg';
import img20 from './images/20.jpeg';
import img21 from './images/21.jpeg';
import img22 from './images/22.jpeg';
import img23 from './images/23.jpg';
import img24 from './images/24.jpg';
import img25 from './images/25.jpg';
import img26 from './images/26.jpg';
import img27 from './images/27.jpg';
import img28 from './images/28.jpeg';
import img29 from './images/29.jpeg';
import img30 from './images/30.jpeg';
import img31 from './images/31.jpeg';
import img32 from './images/32.jpg';
import img33 from './images/33.jpeg';
import img34 from './images/34.jpg';
import img35 from './images/35.jpg';
import img36 from './images/36.jpg';
import img37 from './images/37.jpg';
import img38 from './images/38.jpg';
import img39 from './images/39.jpg';
import img40 from './images/40.jpg';
import img41 from './images/41.jpg';
import img42 from './images/42.jpeg';
import Booking from "../Booking/Booking";
import { deviceType } from 'react-device-detect';

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: (['mobile', 'tablet'].includes(deviceType) ? 1 : 4),
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  cssEase: "linear"
};

var links = [img00, img01, img02, img03, img04, img05, img06, img07, img08,
  img09, img10, img11, img12, img13, img14, img15, img16, img17, img18,
  img19, img20, img21, img22, img23, img24, img25, img26, img27, img28,
  img29, img30, img31, img32, img33, img34, img35, img36, img37, img38,
  img39, img40, img41, img42
];

export function Gallery() {
  return (
    <div className="flex-1 overflow-y-scroll galleryBody">
      <div className="galleryContent">
        <Slider {...settings}>
          {
            links.map((link, index) =>
              <div className="imageGalleryBlock" key={index}>
                <img className="header-image-gallery" alt={index} src={link} />
              </div>
            )
          }
        </Slider>
      </div >
      <Booking />
    </div >
  );
}

export default Gallery;
