import React from "react";
import Logo from "./Logo.js";
import { NavLink } from "react-router-dom";
import './header.css';
import phone from './phone-symbol.svg';
import { useTranslation, Trans } from 'react-i18next';
import { deviceType } from 'react-device-detect';
import Sidebar from "./Sidebar";

export function Header() {

  const { t, i18n } = useTranslation();

  function handleClick(data) {
    i18n.changeLanguage(data)
  }

  return (
    <header className="w-full text-center sticky top-0" id="outer-container">
      {['mobile', 'tablet'].includes(deviceType) && 
        <nav>
          <div className="burger">
            <Sidebar pageWrapID={"page-wrap"} outerContainerId={"outer-container"} />
          </div>
          <Logo />
          <div className="languageBlock">
            <div className="lang">
              <a href="#" onClick={() => handleClick('en')}>EN</a>
              {/* <a onClick={i18n.changeLanguage('en')}>EN</a> */}
            </div>
            <div className="lang">
              <a href="#" onClick={() => handleClick('es')}>ES</a>
              {/* <a onClick={i18n.changeLanguage('es')}>ES</a> */}
            </div>
          </div>
        </nav>
      }
      

      { !['mobile', 'tablet'].includes(deviceType) && 
        <nav>
          <div className="phone"><img src={phone} alt="phone" />{t('footer.phone')}</div>
          <ul>
            <li>
              <NavLink to="/">
                <Trans i18nKey="header.hotel" defaults="<span>H</span>otel" components={{ span: <span /> }} />
              </NavLink>
            </li>
            <li>
              <div className="dropdown">
                <NavLink to="/rooms">
                  <Trans i18nKey="header.rooms" components={{ span: <span /> }} />
                </NavLink>

                <div className="dropdown-content">
                  <NavLink to="/room_double">
                    <Trans i18nKey="rooms.double" components={{ span: <span /> }} />
                  </NavLink>
                  <NavLink to="/room_double_deluxe">
                    <Trans i18nKey="rooms.double_deluxe" components={{ span: <span /> }} />
                  </NavLink>
                  <NavLink to="/room_apartament">
                    <Trans i18nKey="rooms.apartment" components={{ span: <span /> }} />
                  </NavLink>
                  <NavLink to="/room_apartament_deluxe">
                    <Trans i18nKey="rooms.apartment_deluxe" components={{ span: <span /> }} />
                  </NavLink>
                  <NavLink to="/room_junior_suite">
                    <Trans i18nKey="rooms.junior_suite" components={{ span: <span /> }} />
                  </NavLink>
                  <NavLink to="/room_suite_deplex">
                    <Trans i18nKey="rooms.suite_duplex" components={{ span: <span /> }} />
                  </NavLink>
                  <NavLink to="/room_presidental_suite">
                    <Trans i18nKey="rooms.presedential_suite" components={{ span: <span /> }} />
                  </NavLink>
                </div>
              </div>
            </li>
            <li>
              <NavLink to="/gastro">
                <Trans i18nKey="header.gastronomy" defaults="<span>G</span>astronomy" components={{ span: <span /> }} />
              </NavLink>
            </li>
          </ul>

          <Logo />

          <ul>
            <li>
              <NavLink to="/activities">
                <Trans i18nKey="header.activities" defaults="<span>A</span>ctivities" components={{ span: <span /> }} />
              </NavLink>
            </li>
            <li>
              <NavLink to="/events">
                <Trans i18nKey="header.events" defaults="<span>E</span>vents" components={{ span: <span /> }} />
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery">
                <Trans i18nKey="header.gallery" defaults="<span>G</span>allery" components={{ span: <span /> }} />
              </NavLink>
            </li>
          </ul>
          <div className="languageBlock">
            <div className="lang">
              <a href="#" onClick={() => handleClick('en')}>EN</a>
              {/* <a onClick={i18n.changeLanguage('en')}>EN</a> */}
            </div>
            <div className="lang">
              <a href="#" onClick={() => handleClick('es')}>ES</a>
              {/* <a onClick={i18n.changeLanguage('es')}>ES</a> */}
            </div>
          </div>
        </nav>
      }
    </header>
  );
}

export default Header;
