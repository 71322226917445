import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    header: {
                        hotel: '<span>H</span>otel',
                        rooms: '<span>R</span>ooms',
                        gastronomy: '<span>G</span>astronomy',
                        activities: '<span>A</span>ctivities',
                        events: '<span>E</span>vents',
                        gallery: '<span>G</span>allery'
                    },
                    home: {
                        slogan: 'RECONNECT WITH YOURSELF IN THE HEART OF LOS PILARES DE RONDA',
                        places: {
                            title: '<span>T</span>he place where rusticity and elegance meet together',
                            description: 'Our hotel, Los Pilares De Ronda, a secret corner of the world with Andalusian charm converted into a Boutique Hotel just 4.5km from Ronda (Malaga).<br/>We invite you to enjoy the simple things in life with our personalised service, our 18 rooms designed with style, privacy and tranquility; And relax surrounded by our natural setting, horse riding activities and traditional gastronomy.',
                            description2: 'The environment that surrounds our hotel has a great natural wealth, the emblematic Ronda landscape offers us many species of animals and plants. Los Pilares de Ronda, which is only 10 minutes from the Natural Park of La Sierra de Las Nieves, also has very similar characteristics, for example, the famous Pinsapo (Abies Pinsapo) is found in some points of the hotel gardens. The farm that surrounds the hotel is also home to various species of animals typical of the Serranía. El Macho Montes is one of the most characteristic residents of our farm accompanied by Roe deer, Mouflon and Deer.'
                        },
                        buttons: {
                            check_in: 'CHECK IN',
                            check_out: 'CHECK OUT',
                            adult: 'GUESTS',
                            reserve: 'RESERVE',
                            covid: 'Information and measures of COVID-19'
                        },
                        mob_gastronomy: 'Gastronomy',
                        mob_activity: 'Activity',
                        mob_events: 'Events',
                        mob_gallery: 'Gallery'
                    },
                    gastro: {
                        title: '<span>T</span>he <span>t</span>aste of <span>L</span>os <span>P</span>ilares de <span>R</span>onda',
                        description: 'We offer you the possibility of enjoying the gastronomy of the area served next to the chimney in our beautiful salon or outside under a holm oak.',
                        menu: {
                            title: 'Message from our chef',
                            description: 'We understand the cooking art as a tool for the conservation of the ecosystem that surrounds us. Just like the foresters take care of the forest, the farmers of their animals and soil - the chef has a moral implication by picking up the products with care for helping the maintenance and improvement of the countryside. Our effort in Los Pilares de Ronda goes towards the use of the products from our small vegetable farm and respecting timings of the nature, so we can offer our customers unique flavours while they eat in a sustainable way!',
                            menu: 'Menu',
                            opening: 'Opening hours',
                            breakfast: 'Breakfast: Daily 8.00-11.00',
                            dinner: 'Dinner: Friday & Saturday 19:30 - 23:00'
                        },
                    },
                    activities: {
                        contact_link: 'Contact us for more information and reservations!',
                        horse: {
                            title: 'Hourse Trails',
                            description: 'One of our most recognizable and demanded activities is our horse riding routes. We offer a harmonious ride with noble and quiet horses through our landscapes that make our hotel and its surroundings so emblematic. An immersion in nature that will make you see beauty and purity in another way. At all times, you will be accompanied by our monitor, who also traces the route.',
                            available: 'Available from April 15th',
                        },
                        wine: {
                            title: 'Wine Tasting',
                            description: 'Enjoy your private tasting of the local wines with a sommelier. All the wines are carefully selected from the best Ronda wineries and paired with local meet/cheese cuts.<br/>We offer our guests to spend the charming evening beside the chimney listening to amusing speech of the wine professional or to inhale the freshness of the countryside under our oak tree while relish Ronda wines.',
                            prior: 'Prior reservation required',
                        },
                        swimming: {
                            title: 'Swimming Pools',
                            description: 'We are glad to invite our guests to enjoy 2 pools and refresh during the hot summer day! Get some tan and enjoy the days by the swimming pool in the heart of Serrania de Ronda!',
                            available: 'Available from May',
                        },
                        yoga: {
                            title: 'Yoga',
                            description: 'We believe that Los Pilares de Ronda is the place to reconnect with yourself, so what can be better than yoga and meditation in such a peaceful ambiance. Book your private class or attend the group classes every Saturday morning!',
                            coming: 'Prior reservation required',
                        },
                        picnic: {
                            title: 'Private Picnic',
                            description: 'Want to escape from the everyday bustle? This is the perfect opportunity! Our chef offers the picnic basket with products from the area and beverages upon your preference. Alone, with your beloved ones or in the company of friends - the picnic will create the atmosphere of tranquility and stop the time for a bit.',
                            available: 'Available from June',
                        },

                    },
                    rooms: {
                        description: '18 personalised rooms with style, privacy and tranquility.<br/>From the Standard Double Room to the Grand Deluxe Suite we are putting our best effort into emphasising every detail and history of its presence at Hotel Los Pilares de Ronda.',
                        double: 'Double',
                        double_deluxe: 'Double Deluxe',
                        apartment: 'Apartment',
                        apartment_deluxe: 'Apartment Deluxe',
                        junior_suite: 'Junior Suite',
                        suite_duplex: 'Suite Duplex',
                        presedential_suite: 'Presedential Suite',
                        booking: 'Book now',
                        options: {
                            free_wifi: 'Free Wifi',
                            shower: 'Shower',
                            garden: 'Garden View',
                            shower_bath: 'Shower / bathtab',
                            terrace: 'Terrace',
                            kitchen: 'Kitchen',
                            private_terrace: 'Private Terrace',
                            living_room: 'Living room',
                            spacious_shower: 'Spacious Shower',
                            hairdyer: 'Hairdryer',
                            minibar: 'Minibar',
                            coffe_machine: 'Coffe Machine',
                            sauna: 'Sauna',
                            bathtub: 'Bathtub',
                            working_area: 'Working area',
                            chimney: 'Chimney',
                            balcony: 'Balcony'
                        },
                        doble_page: {
                            title: 'Double',
                            description: 'Our selection includes comfortable double Rooms, ideal for an easy and fast escape to Ronda. With wonderful views to our gardens, the Rooms include High-Speed WiFi, TV with a wide range of channels and fully equipped bathrooms.'
                        },
                        doble_deluxe_page: {
                            title: 'Double Deluxe',
                            description: 'Medium-sized rooms with comfortable beds, in a cozy mediterranean style. Mix of the typical decoration from the area and antique details can not leave indifferent any guest.<br/>Most of our room has the access to our garden patios.'
                        },
                        apartment_page: {
                            title: 'Apartment',
                            description: 'Like a home away from home, our apartments include all the elects of a Mediterranean House: Kitchen, living room, private garden, terrace to feel like at home, fully equipped bathrooms and welcome amenities.'
                        },
                        apartment_deluxe_page: {
                            title: 'Apartment Deluxe',
                            description: 'Our Deluxe Studio Apartments offer accommodation for 2 people. They can be distinguished by their privacy, full kitchen, living area, private garden and very spacious terraces to feel at home away from home.'
                        },
                        junior_page: {
                            title: 'Junior Suite',
                            description: 'Style and privacy. Those are the words that best define our Junior Suites. With an unique attention to detail, these Suites include exclusive touches that could take us to the charm of Paris, or portray the elegance of equestrian arts, for an unforgettable stay.'
                        },
                        duplex_page: {
                            title: 'Suite Duplex',
                            description: 'For travellers that want more than an ordinary stay, our Unique Suites allow to enjoy the luxury of Ronda. Divided in two floors our suite Duplex include the living room with the chimney and bedroom with king size bed, as well as an external private area to enjoy the superb climate of Ronda'
                        },
                        presendential_page: {
                            title: 'Presidential Suite',
                            description: 'Inspired in the old palaces of the area, its our Top level Suite. Allow yourself to enjoy the luxury and the Mediterranean Hospitality. The mighty fireplace will shed light on the marble floors of the living room, and the Sauna will prepare you for a Spa-like Roman Bathtub.'
                        }
                    },
                    footer: {
                        contacts: '<span>C</span>ontact <span>i</span>nformation',
                        follow: '<span>F</span>ollow <span>U</span>s',
                        weather: '<span>W</span>EATHER',
                        terms: 'Terms and conditions',
                        privacy: 'Privacy policy',
                        hotel: 'Hotel los pilares de ronda',
                        year: '2022',
                        addres1: 'Carr. de Algeciras, km 4, 5,',
                        addres2: '29400 Ronda, Málaga',
                        phone: '+34 951 180 069',
                        email: 'recepcion@lospilaresderonda.com',
                        instagram: '@lospilaresderonda'
                    },
                    events: {
                        wedding: {
                            title: '<span>Y</span>our <span>P</span>erfect <span>W</span>edding',
                            description: 'The stunning views and pure nature create a perfect location to make your wedding day truly special in every way!<br/>The uniquely designed marble table can allocate up to 50 people and a private table for the bride and groom. The surrounded area can be set with individual tables to increase the capacity of the guests up to 100 people (in case of bigger capacity, we provide the solution upon the request).<br/>Allow Lo Pilares de Ronda be the haven to create your very own Spanish fairy tail!'
                        },
                        corporate: {
                            title: '<span>C</span>orporate and <span>P</span>rivate events',
                            description: 'There is no better place to spend the special occasion surrounded by the gardens where each corner is a masterpiece. Depending on the capacity, we offer different venue locations and style of the event.<br/>All you need to do is to contact us and we will be glad to organise and host your event!'
                        },
                        request: {
                            title: '<span>L</span>eave your direct request',
                            name: 'Name',
                            email: 'Email Address',
                            contact: 'Contact number',
                            message: 'Leave your message (please mention # of guests and date of the event)',
                            submit: 'Send Message'
                        },
                        dialog: {
                            title: 'Sucess',
                            message: 'Thank you for your request. Our team contact you shortly!',
                            continue: 'Close'
                        }
                    }
                }
            },
            es: {
                translation: {
                    header: {
                        hotel: '<span>H</span>otel',
                        rooms: '<span>H</span>abitaciones',
                        gastronomy: '<span>G</span>astronomía',
                        activities: '<span>A</span>ctividades',
                        events: '<span>E</span>ventos',
                        gallery: '<span>G</span>alería'
                    },
                    home: {
                        slogan: 'RECONECTA CONTIGO MISMO EN EL CORAZÓN DE LOS PILARES DE RONDA',
                        places: {
                            title: 'El lugar donde lo rural y lo elegante se unen',
                            description: 'Nuestro hotel, Los Pilares De Ronda, un rincón secreto del mundo con encanto andaluz convertido en Hotel Boutique a tan solo 4,5 km de Ronda (Málaga). Te invitamos a disfrutar de las cosas simples de la vida con nuestro servicio personalizado, 18 habitaciones diseñadas con estilo, privacidad y tranquilidad; Y relájate rodeado de nuestro entorno natural, actividades ecuestres y gastronomía tradicional.',
                            description2: 'El entorno que rodea a nuestro hotel tiene una gran riqueza natural, el emblemático paisaje rondeño nos ofrece multitud de especies animales y vegetales. Los Pilares de Ronda, que se encuentra a tan solo 10 minutos del Parque Natural de la Sierra de Las Nieves, también tiene características muy similares, por ejemplo, el famoso Pinsapo (Abies Pinsapo) se encuentra en algunos puntos de los jardines del hotel. La finca que rodea el hotel es también el hogar de varias especies de animales típicos de la Serranía. El Macho Montes es uno de los vecinos más característicos de nuestra finca acompañado de Corzos, Muflones y Ciervos.'
                        },
                        buttons: {
                            check_in: 'ENTRADA',
                            check_out: 'SALIDA',
                            adult: 'HUÉSPEDES',
                            reserve: 'RESERVAR',
                            covid: 'Información y medidas del COVID-19'
                        },
                        mob_gastronomy: 'Gastronomía',
                        mob_activity: 'Actividades',
                        mob_events: 'Eventos',
                        mob_gallery: 'Galería'
                    },
                    gastro: {
                        title: '<span>E</span>l <span>s</span>abor <span>d</span>e <span>L</span>os <span>P</span>ilares de <span>R</span>onda',
                        description: 'Te ofrecemos la posibilidad de disfrutar de la gastronomía de la zona servida junto a la chimenea en nuestro precioso salón o al aire libre bajo una encina.',
                        menu: {
                            title: 'Notas de nuestra chef',
                            description: 'Entendemos el arte de cocinar como una herramienta para la conservación del ecosistema que nos rodea. Al igual que los silvicultores cuidan el bosque, los granjeros de sus animales y del suelo, el chef tiene una implicación moral al recoger los productos con cuidado para ayudar al mantenimiento y mejora del campo. Nuestro esfuerzo en Los Pilares de Ronda se dirige al aprovechamiento de los productos de nuestro huerto, apoyando y respetando los tiempos de la naturaleza, para poder ofrecer a nuestros clientes sabores únicos mientras comen de forma sostenible!',
                            menu: 'Menú',
                            opening: 'Horario de apertura',
                            breakfast: 'Desayuno: todos los días de 8:00 a 11:00',
                            dinner: 'Cena: Viernes y Sábado 19:30 - 23:00'
                        },
                    },
                    activities: {
                        contact_link: '¡Contáctanos para más información y reservas!',
                        horse: {
                            title: 'Rutas a caballo',
                            description: 'Una de nuestras actividades más reconocibles y demandadas son nuestras rutas a caballo. Ofrecemos un paseo armonioso con caballos nobles y tranquilos por nuestros paisajes que hacen tan emblemáticos a nuestro hotel y su entorno. Una inmersión en la naturaleza que te hará ver la belleza y la pureza de otra forma. En todo momento irás acompañado de nuestro monitor.',
                            available: 'Disponible a partir del 15 de abril hasta el noviembre.',
                        },
                        wine: {
                            title: 'Cata de vinos',
                            description: 'Disfrute de su degustación privada de los vinos locales con un sumiller. Todos los vinos son cuidadosamente seleccionados de las mejores bodegas de Ronda y maridados con vinos locales carne/cortes de queso. Ofrecemos a nuestros huéspedes pasar la encantadora velada junto a la chimenea escuchando el divertido discurso del profesional del vino o inhalar la frescura del campo bajo nuestro roble mientras saborea los vinos de Ronda.',
                            prior: 'Se requiere reserva previa',
                        },
                        swimming: {
                            title: 'Piscinas',
                            description: '¡Nos complace invitar a nuestros huéspedes a disfrutar de 2 piscinas y refrescarse durante el caluroso día de verano! ¡Broncéate y disfruta de los días junto a la piscina en plena Serranía de Ronda!',
                            available: '',
                        },
                        yoga: {
                            title: 'Yoga',
                            description: 'Creemos que Los Pilares de Ronda es el lugar para reconectar contigo mismo, así que qué mejor que el yoga y la meditación en un ambiente tan tranquilo. Reserva tu clase privada o asiste a las clases grupales.',
                            coming: 'Se requiere reserva previa',
                        },
                        picnic: {
                            title: 'Picnic Privado',
                            description: '¿Quieres escapar del ajetreo cotidiano? ¡Esta es la oportunidad perfecta! Nuestro chef ofrece la cesta de picnic con productos de la zona y bebidas según su preferencia. Solo, con sus seres queridos o en compañía de amigos, el picnic creará una atmósfera de tranquilidad y detendrá el tiempo por un momento.',
                            available: 'Se requiere reserva previa',
                        },
                    },
                    rooms: {
                        description: '18 habitaciones personalizadas con estilo, privacidad y tranquilidad. Desde la Habitación Doble Estándar hasta la Suite Gran Deluxe estamos poniendo nuestro mayor esfuerzo en resaltar cada detalle e historia de su presencia en el Hotel Los Pilares de Ronda.',
                        doble: 'Doble',
                        doble_deluxe: 'Doble Deluxe',
                        apartment: 'Apartamento',
                        apartment_deluxe: 'Apartamento Deluxe',
                        junior_suite: 'Junior Suite',
                        suite_duplex: 'Suite Duplex',
                        presedential_suite: 'Suite Precidencial',
                        booking: 'Reserva ahora',
                        options: {
                            free_wifi: 'Wi-Fi gratis',
                            shower: 'Ducha',
                            garden: 'Vistas al jardín',
                            shower_bath: 'Ducha / bañera',
                            terrace: 'Terraza',
                            kitchen: 'Cocina',
                            private_terrace: 'Terraza privada',
                            living_room: 'Sala de estar',
                            spacious_shower: 'Ducha espaciosa',
                            hairdyer: 'Secador de pelo',
                            minibar: 'Minibar',
                            coffe_machine: 'Cafetera',
                            sauna: 'Sauna',
                            bathtub: 'Bañera',
                            working_area: 'Área de trabajo',
                            chimney: 'Chimenea',
                            balcony: 'Balcón'
                        },
                        doble_page: {
                            title: 'Doble',
                            description: 'Nuestra selección incluye Habitaciones dobles cómodas, ideales para una escapada fácil y rápida a Ronda. Con maravillosas vistas a nuestros jardines, las Habitaciones incluyen WiFi de alta velocidad, TV con una amplia gama de canales y baños completos.'
                        },
                        doble_deluxe_page: {
                            title: 'Doble Deluxe',
                            description: 'Habitaciones medianas con cama de matrimonio, en un acogedor estilo mediterráneo. La mezcla de la decoración típica de la zona y los detalles antiguos no pueden dejar indiferente a ningún huésped.<br/>La mayor parte de nuestra habitación tiene acceso a nuestros patios con jardín.'
                        },
                        apartment_page: {
                            title: 'Apartamento',
                            description: 'Como un hogar lejos de casa, nuestros apartamentos incluyen todas las opciones de una Casa Mediterránea: Cocina, sala de estar, jardín privado, terraza para sentirse como en casa, baños completos.'
                        },
                        apartment_deluxe_page: {
                            title: 'Apartamento Deluxe',
                            description: 'Nuestros Apartamentos Estudio Deluxe ofrecen alojamiento para 2 personas. Se distinguen por su privacidad, cocina completa, sala de estar, jardín privado y terrazas muy amplias para sentirse como en casa fuera de casa.'
                        },
                        junior_page: {
                            title: 'Junior Suite',
                            description: 'Estilo y privacidad. Esas son las palabras que mejor definen a nuestras Junior Suites. Con una atención única al detalle, estas Suites incluyen toques exclusivos que nos pueden trasladar al encanto de París, o retratar la elegancia de las artes ecuestres, para una estadía inolvidable.'
                        },
                        duplex_page: {
                            title: 'Suite Duplex',
                            description: 'Para los viajeros que quieren más que una estadía ordinaria, nuestras Suites permiten disfrutar del lujo de Ronda. Dividida en dos plantas, nuestra suite Dúplex incluye el salón con chimenea y el dormitorio con cama king size, así como una zona privada exterior para disfrutar del inmejorable clima de Ronda.'
                        },
                        presendential_page: {
                            title: 'Suite Precidencial',
                            description: 'La habitación más grande de nuestra selección, inspirada en los antiguos cortijos de la zona, es nuestra Gran Suite. Permítase disfrutar del lujo y la Hospitalidad Mediterránea.'
                        }
                    },
                    footer: {
                        contacts: '<span>I<span>nformación del <span>c<span>ontacto',
                        follow: '<span>S</span>íganos',
                        weather: '<span>E</span>l <span>t</span>iempo',
                        terms: 'Términos y condiciones',
                        privacy: 'Política de privacidad',
                        hotel: 'Hotel los pilares de ronda',
                        year: '2022',
                        addres1: 'Carr. de Algeciras, km 4, 5,',
                        addres2: '29400 Ronda, Málaga',
                        phone: '+34 951 180 069',
                        email: 'recepcion@lospilaresderonda.com',
                        instagram: '@lospilaresderonda'
                    },
                    events: {
                        wedding: {
                            title: '<span>T</span>u boda <span>p</span>erfecta',
                            description: '¡Las impresionantes vistas y la naturaleza pura crean un lugar perfecto para que el día de su boda sea realmente especial en todos los sentidos!<br/>La mesa de mármol de diseño exclusivo puede albergar hasta 50 personas y una mesa privada para los novios. El entorno se puede ambientar con mesas individuales para aumentar el aforo de comensales hasta 100 personas (en caso de mayor aforo, damos solución a petición).<br/>¡Permite que Lo Pilares de Ronda sea el refugio para crear tu propio cuento de hadas español!'
                        },
                        corporate: {
                            title: '<span>E</span>ventos <span>c</span>orporativos y <span>p</span>rivados',
                            description: 'No hay mejor lugar para pasar la ocasión especial rodeado de jardines donde cada rincón es una obra maestra. Dependiendo de la capacidad, nosotros ofrecen diferentes lugares de celebración y estilo del evento.<br/>¡Todo lo que necesita hacer es ponerse en contacto con nosotros y estaremos encantados de organizar y acoger tu evento!'
                        },
                        request: {
                            title: '<span>D</span>eje su solicitud directa',
                            name: 'Nombre',
                            email: 'Email',
                            contact: 'Numero de contacto',
                            message: 'Deja tu mensaje (por favor mencione # de invitados y fecha del evento)',
                            submit: 'Enviar mensaje'
                        },
                        dialog: {
                            title: 'Éxito',
                            message: 'Gracias por su solicitud. ¡Nuestro equipo se pondrá en contacto con usted en breve!',
                            continue: 'Cerca'
                        }
                    }
                }
            }
        }
    });

export default i18n;
