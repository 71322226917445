import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import "./sidebar.css";

const Sidebar = () => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    <Menu isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
      <NavLink to="/" onClick={closeSideBar}>
        <Trans i18nKey="header.hotel" components={{ span: <span /> }} />
      </NavLink>
      <NavLink to="/rooms" onClick={closeSideBar}>
        <Trans i18nKey="header.rooms" components={{ span: <span /> }} />
      </NavLink>
      <NavLink to="/gastro" onClick={closeSideBar}>
        <Trans i18nKey="header.gastronomy" components={{ span: <span /> }} />
      </NavLink>
      <NavLink to="/activities" onClick={closeSideBar}>
        <Trans i18nKey="header.activities" components={{ span: <span /> }} />
      </NavLink>
      <NavLink to="/events" onClick={closeSideBar}>
        <Trans i18nKey="header.events" components={{ span: <span /> }} />
      </NavLink>
      <NavLink to="/gallery" onClick={closeSideBar}>
        <Trans i18nKey="header.gallery" components={{ span: <span /> }} />
      </NavLink>
    </Menu>
  );
};

export default Sidebar;
