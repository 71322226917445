import React, { useState } from "react";
import "./home.css";
import logo from "./ronda.jpg";
import Slider from "react-slick";
import { useTranslation, Trans } from "react-i18next";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "react-datepicker/dist/react-datepicker.css";
import { deviceType } from "react-device-detect";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Booking from "../Booking/Booking";

import pl1 from "./images/places/1.jpeg";
import pl2 from "./images/places/2.jpeg";
import pl3 from "./images/places/3.jpg";
import pl4 from "./images/places/4.jpg";
import pl5 from "./images/places/5.jpeg";

import pk1 from "./images/park/6.jpg";
import pk2 from "./images/park/7.jpeg";
import pk3 from "./images/park/8.jpeg";
import pk4 from "./images/park/9.jpeg";
import pk5 from "./images/park/10.jpg";

import gastronomy from "./images/gastronomy.jpg";
import mob_picture from "./images/mob_picture.jpg";
import gallery from "./images/gallery.jpeg";
import events from "./images/events.jpg";
import activity from "./images/activity.jpeg";

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 10000,
  cssEase: "linear",
};

var placesImages = [pl1, pl2, pl3, pl4, pl5];
var parkImages = [pk1, pk2, pk3, pk4, pk5];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(81, 126, 105, 0.534) !important",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(81, 126, 105, 0.534) !important",
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: "rgba(81, 126, 105, 0.534) !important",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isFocused ? "white" : isSelected ? "#181717" : undefined,
    background: isFocused
      ? "rgba(81, 126, 105, 0.9)"
      : isSelected
      ? "rgba(81, 126, 105, 1)"
      : undefined,
    zIndex: 1,
  }),
};

export function Home() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedAdults, setSelectedAdults] = useState(null);
  // const [selectedChildrens, setSelectedChildrens] = useState(null);

  const adultsOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];

  const { t } = useTranslation();

  const handleClick = () => {
    console.log(fromDate);
    console.log(moment(fromDate).format("DD-MM-YYYY"));
    let url = "https://booking.avirato.com/?code=13109433&lang=en";
    if (fromDate)
      url = url + "&startDate=" + moment(fromDate).format("DD-MM-YYYY");
    if (toDate) url = url + "&endDate=" + moment(toDate).format("DD-MM-YYYY");
    if (selectedAdults) url = url + "&adults=" + selectedAdults;

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");

  return (
    <div className="flex-1 overflow-y-scroll homeBody">
      {!['mobile', 'tablet'].includes(deviceType) && 
        <div className="banner">
          {/*https://stackoverflow.com/a/62329932*/}
          <video
            src={require('./video.mp4')}
            type="video/mp4"
            autoPlay={true}
            preload="auto"
            controls = {false}
            loop = {true}
            muted = {true}
            id="myVideo"
            poster={logo}
          />
          <div className="content">
            <AnimationOnScroll
              animateIn="fadeUp"
              animateOnce={true}
              duration={2}
              animatePreScroll={false}
            >
              <div className="presentation">
                <h1 className="place-title">{t("home.slogan")}</h1>
                <div className="homeButtonsHome">
                  <div className="homeButtonHome">
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={fromDate}
                      minDate={new Date()}
                      onChange={(fromDate) => setFromDate(fromDate)}
                      onCalendarClose={handleCalendarClose}
                      onCalendarOpen={handleCalendarOpen}
                      placeholderText={t("home.buttons.check_in")}
                    />
                  </div>
                  <div className="homeButtonHome">
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      minDate={
                        fromDate
                          ? new Date(fromDate + 3600 * 1000 * 24)
                          : new Date(Date.now() + 3600 * 1000 * 24)
                      }
                      selected={toDate}
                      onChange={(toDate) => setToDate(toDate)}
                      onCalendarClose={handleCalendarClose}
                      onCalendarOpen={handleCalendarOpen}
                      placeholderText={t("home.buttons.check_out")}
                    />
                  </div>
                  <div className="homeButtonHome">
                    <Select
                      styles={customStyles}
                      value={selectedAdults}
                      onChange={setSelectedAdults}
                      options={adultsOptions}
                      placeholder={t("home.buttons.adult")}
                      singleValue={true}
                    />
                  </div>
                  {/* <div className="homeButtonHome"
                  onClick={() => { setModalOpen(true) }}
                >
                  {t('home.buttons.adult')}
                </div> */}
                  <div className="homeButtonHome" onClick={handleClick}>
                    {/* {t('rooms.booking')} */}
                    {t("home.buttons.reserve")}
                  </div>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      }

      {['mobile', 'tablet'].includes(deviceType) && 
        <div className="banner">
          <div className="content">
            <div className="presentation">
              <h1 className="place-title">{t("home.slogan")}</h1>
            </div>
          </div>
        </div>
      }

      {!['mobile', 'tablet'].includes(deviceType) && 
        <div className="homegPlaceContentBlock">
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={3}
          >
            <div className="homegPlaceContent">
              <h1 className="homegPlaceTitle">
                <Trans
                  i18nKey="home.places.title"
                  components={{ span: <span /> }}
                />
              </h1>
              <p>
                <Trans
                  i18nKey="home.places.description"
                  components={{ span: <span /> }}
                />
              </p>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={3}
          >
            <div className="homegPlace">
              <Slider {...settings}>
                {placesImages.map((image, index) => (
                  <div className="imageHomeBlock" key={index}>
                    <img
                      className="header-image-home"
                      alt={index}
                      src={image}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </AnimationOnScroll>
          <div className="homegPlaceContent">
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              duration={3}
            >
              <p>
                <Trans
                  i18nKey="home.places.description2"
                  components={{ span: <span /> }}
                />
              </p>
            </AnimationOnScroll>
          </div>
          <div className="homegPlace">
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              duration={3}
            >
              <Slider {...settings}>
                {parkImages.map((image, index) => (
                  <div className="imageHomeBlock" key={index}>
                    <img
                      className="header-image-home"
                      alt={index}
                      src={image}
                    />
                  </div>
                ))}
              </Slider>
            </AnimationOnScroll>
          </div>
        </div>
      }

      {['mobile', 'tablet'].includes(deviceType) &&
        <div className="homegPlaceContentBlock">
          <div className="homegPlaceContent">
            <h1 className="homegPlaceTitle">
              <Trans
                i18nKey="home.places.title"
                components={{ span: <span /> }}
              />
            </h1>
            <p>
              <Trans
                i18nKey="home.places.description"
                components={{ span: <span /> }}
              />
            </p>
          </div>
        </div>
      }

      {['mobile', 'tablet'].includes(deviceType) && 
        <div className="homegPlace">
          <div className="imageHomeBlock">
            <img className="header-image-home" src={mob_picture} alt="mobileImage" />
          </div>
        </div>
      }

      {['mobile', 'tablet'].includes(deviceType) && (
        <div className="homeContentItemsBlock">
          <div
            className="homeContentItem"
            key="gastronomy"
            style={{ backgroundImage: `url(${gastronomy})` }}
          >
            <NavLink to="/gastro" className="homeButtonMob">
              {t("home.mob_gastronomy")}
            </NavLink>
          </div>
          <div
            className="homeContentItem"
            key="activity"
            style={{ backgroundImage: `url(${activity})` }}
          >
            <NavLink to="/activities" className="homeButtonMob">
              {t("home.mob_activity")}
            </NavLink>
          </div>
          <div
            className="homeContentItem"
            key="events"
            style={{ backgroundImage: `url(${events})` }}
          >
            <NavLink to="/events" className="homeButtonMob">
              {t("home.mob_events")}
            </NavLink>
          </div>
          <div
            className="homeContentItem"
            key="gallery"
            style={{ backgroundImage: `url(${gallery})` }}
          >
            <NavLink to="/gallery" className="homeButtonMob">
              {t("home.mob_gallery")}
            </NavLink>
          </div>
        </div>
      )}
      {/* {modalOpen && <Modal setOpenModal={setModalOpen} />} */}
      {['mobile', 'tablet'].includes(deviceType) && <Booking/>}
    </div>
  );
}

export default Home;
