import React from "react";
import { useTranslation } from 'react-i18next';

export function Booking() {
  const { t } = useTranslation();

  const handleClick = () => {
    const newWindow = window.open('https://booking.avirato.com/?code=13109433&lang=en', '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  return (
    <div className="fixed">
      <div className="bookingButton" onClick={handleClick}>{t('rooms.booking')}</div>
    </div>
  );
}

export default Booking;
