import React, { useState } from "react";
import "./activities.css";
import { useTranslation, Trans } from "react-i18next";
import ac1 from "./images/1.jpeg";
import ac2 from "./images/2.jpeg";
import ac4 from "./images/4.jpeg";
import ac5 from "./images/5.jpeg";
import ac6 from "./images/6.jpeg";
import Booking from "../Booking/Booking";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { deviceType } from "react-device-detect";
import ReactCardFlip from "react-card-flip";

export function Activities() {
  const { t } = useTranslation();
  const [flip, setFlip] = useState(false);
  const [flipWine, setFlipWine] = useState(false);
  const [flipSwim, setFlipSwim] = useState(false);
  const [flipYoga, setFlipYoga] = useState(false);
  const [flipPicnic, setFlipPicnic] = useState(false);

  const handleIsFlipped = () => {
    setFlip(!flip);
  };

  const handleIsFlippedWine = () => {
    setFlipWine(!flipWine);
  };

  const handleIsFlippedSwim = () => {
    setFlipSwim(!flipSwim);
  };

  const handleIsFlippedYoga = () => {
    setFlipYoga(!flipYoga);
  };

  const handleIsFlippedPicnic = () => {
    setFlipPicnic(!flipPicnic);
  };

  return (
    <div className="flex-1 overflow-y-scroll activitiesBody">
      {!['mobile'].includes(deviceType) && 
        <div className="activitiesBlock">
          <div className="activitiesBlockLeft">
            <div className="activitiesImageBlock">
              <img
                className="activityImageContent activityImageLeft"
                alt="Hourse Trails"
                src={ac1}
              />
            </div>
            <div className="activityContentBlock">
              <div className="activityContent activityContentLeft">
                <h3>{t("activities.horse.title")}</h3>
                <p>{t("activities.horse.description")}</p>
                <div className="activityContentBottom">
                  {/* <p>{t('activities.horse.available')}</p> */}
                  <a>{t("activities.contact_link")}</a>
                </div>
              </div>
            </div>
          </div>

          <div className="activitiesBlockRight">
            <div className="activityContentBlock">
              <div className="activityContent  activityContentRight">
                <h3>{t("activities.wine.title")}</h3>
                <p>
                  <Trans
                    i18nKey="activities.wine.description"
                    components={{ br: <br /> }}
                  />
                </p>
                <div className="activityContentBottom">
                  <p>{t("activities.wine.prior")}</p>
                  <a>{t("activities.contact_link")}</a>
                </div>
              </div>
            </div>
            <div className="activitiesImageBlock">
              <img
                className="activityImageContent"
                alt="Wine Tasting"
                src={ac2}
              />
            </div>
          </div>
        </div>
      }
      {['mobile'].includes(deviceType) && (
        <div className="activitiesBlock">
          <ReactCardFlip isFlipped={flip} flipDirection="vertical">
            <div
              className="activityContentItem"
              key="gastronomy"
              style={{ backgroundImage: `url(${ac1})` }}
              onClick={handleIsFlipped}
            />

            <div
              className="activityContent activityContentLeft"
              onClick={handleIsFlipped}
            >
              <h3>{t("activities.horse.title")}</h3>
              <p>{t("activities.horse.description")}</p>
              <div className="activityContentBottom">
                {/* <p>{t('activities.horse.available')}</p> */}
                <a>{t("activities.contact_link")}</a>
              </div>
            </div>
          </ReactCardFlip>

          <ReactCardFlip isFlipped={flipWine} flipDirection="vertical">
            <div
              className="activityContentItem"
              key="gastronomy"
              style={{ backgroundImage: `url(${ac2})` }}
              onClick={handleIsFlippedWine}
            />

            <div
              className="activityContent activityContentLeft"
              onClick={handleIsFlippedWine}
            >
              <h3>{t("activities.wine.title")}</h3>
              <p>
                <Trans
                  i18nKey="activities.wine.description"
                  components={{ br: <br /> }}
                />
              </p>
              <div className="activityContentBottom">
                <p>{t("activities.wine.prior")}</p>
                <a>{t("activities.contact_link")}</a>
              </div>
            </div>
          </ReactCardFlip>

          <ReactCardFlip isFlipped={flipYoga} flipDirection="vertical">
            <div
              className="activityContentItem"
              key="gastronomy"
              style={{ backgroundImage: `url(${ac6})` }}
              onClick={handleIsFlippedYoga}
            />

            <div
              className="activityContent activityContentLeft"
              onClick={handleIsFlippedYoga}
            >
              <h3>{t("activities.yoga.title")}</h3>
              <p>{t("activities.yoga.description")}</p>
              <div className="activityContentBottom">
                <p>
                  <Trans
                    i18nKey="activities.yoga.coming"
                    defaults="Prior reservation required"
                    components={{ br: <br /> }}
                  />
                </p>
                <a>{t("activities.contact_link")}</a>
              </div>
            </div>
          </ReactCardFlip>

          <ReactCardFlip isFlipped={flipSwim} flipDirection="vertical">
            <div
              className="activityContentItem"
              key="gastronomy"
              style={{ backgroundImage: `url(${ac4})` }}
              onClick={handleIsFlippedSwim}
            />

            <div
              className="activityContent activityContentLeft"
              onClick={handleIsFlippedSwim}
            >
              <h3>{t("activities.swimming.title")}</h3>
              <p>{t("activities.swimming.description")}</p>
              <div className="activityContentBottom">
                <p>{t("activities.swimming.available")}</p>
                <a>{t("activities.contact_link")}</a>
              </div>
            </div>
          </ReactCardFlip>

          <ReactCardFlip isFlipped={flipPicnic} flipDirection="vertical">
            <div
              className="activityContentItem"
              key="gastronomy"
              style={{ backgroundImage: `url(${ac5})` }}
              onClick={handleIsFlippedPicnic}
            />

            <div
              className="activityContent activityContentLeft"
              onClick={handleIsFlippedPicnic}
            >
              <h3>{t("activities.picnic.title")}</h3>
              <p>{t("activities.picnic.description")}</p>
              <div className="activityContentBottom">
                <p>{t("activities.picnic.available")}</p>
                <a>{t("activities.contact_link")}</a>
              </div>
            </div>
          </ReactCardFlip>
        </div>
      )}

      {!['mobile'].includes(deviceType) && (
        <AnimationOnScroll
          animateIn="bounceInUp"
          animateOnce={true}
          duration={2.5}
        >
          <div className="activitiesBlock">
            <div className="activitiesBlockLeft">
              <div className="activitiesImageBlock">
                <img
                  className="activityImageContent activityImageLeft"
                  alt="Hourse Trails"
                  src={ac6}
                />
              </div>
              <div className="activityContentBlock">
                <div className="activityContent  activityContentLeft">
                  <h3>{t("activities.yoga.title")}</h3>
                  <p>{t("activities.yoga.description")}</p>
                  <div className="activityContentBottom">
                    <p>
                      <Trans
                        i18nKey="activities.yoga.coming"
                        defaults="Prior reservation required"
                        components={{ br: <br /> }}
                      />
                    </p>
                    <a>{t("activities.contact_link")}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="activitiesBlockRight">
              <div className="activityContentBlock">
                <div className="activityContent  activityContentRight">
                  <h3>{t("activities.swimming.title")}</h3>
                  <p>{t("activities.swimming.description")}</p>
                  <div className="activityContentBottom">
                    <p>{t("activities.swimming.available")}</p>
                    <a>{t("activities.contact_link")}</a>
                  </div>
                </div>
              </div>
              <div className="activityImageContent">
                <img
                  className="activityImageContent"
                  alt="Wine Tasting"
                  src={ac4}
                />
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      )}
      {!['mobile'].includes(deviceType)  && (
        <AnimationOnScroll
          animateIn="bounceInUp"
          animateOnce={true}
          duration={2.5}
        >
          <div className="activitiesBlock">
            <div className="activitiesBlockCenter">
              <div className="activitiesImageBlock">
                <img
                  className="activityImageContent activityImageLeft"
                  alt="Hourse Trails"
                  src={ac5}
                />
              </div>
              <div className="activityContentBlock">
                <div className="activityContent activityContentLeft">
                  <h3>{t("activities.picnic.title")}</h3>
                  <p>{t("activities.picnic.description")}</p>
                  <div className="activityContentBottom">
                    <p>{t("activities.picnic.available")}</p>
                    <a>{t("activities.contact_link")}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      )}
      <Booking />
    </div>
  );
}

export default Activities;
