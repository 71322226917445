import React, { useState } from "react";
import "./events.css";
import { useTranslation, Trans } from "react-i18next";
import im1 from "./images/1.jpg";
import im2 from "./images/2.jpg";
import im3 from "./images/3.jpg";
import im4 from "./images/4.jpg";
import im5 from "./images/5.jpg";
import Booking from "../Booking/Booking";
import Modal from "../Modal/CustomModal";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { send } from "emailjs-com";
import { deviceType } from "react-device-detect";

import weddings from "./images/weddings.png";

export function Events() {
  const [modalOpen, setModalOpen] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    email: "",
    contact: "",
    message: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_m6w4d8i", "template_3sfb1dd", toSend, "0ruCl0kEmaPZ-H5qb")
      .then((response) => {
        setModalOpen(true);
        setToSend({
          from_name: "",
          email: "",
          contact: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const { t } = useTranslation();

  return (
    <div className="flex-1 overflow-y-scroll eventsBody">
    {!['mobile', 'tablet'].includes(deviceType) && 
        <div className="eventsBlock">
          <div className="eventsBlockLeft">
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              animatePreScroll={false}
              duration={2.5}
            >
              <img className="imageContentTop" alt="image1" src={im1} />
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              animatePreScroll={false}
              duration={2}
            >
              <img className="imageContentCenter" alt="image1" src={im2} />
            </AnimationOnScroll>
          </div>
          <div className="eventsBlockCenter">
            <div className="eventContentBlock">
              <AnimationOnScroll
                animateIn="bounceInUp"
                animateOnce={true}
                animatePreScroll={false}
                duration={1}
              >
                <div className="eventContent eventContentLeft">
                  <h3>
                    <AnimationOnScroll
                      animateIn="bounceInUp"
                      animateOnce={true}
                      animatePreScroll={false}
                      duration={1.5}
                    >
                      <Trans
                        i18nKey="events.wedding.title"
                        components={{ span: <span /> }}
                      />
                    </AnimationOnScroll>
                  </h3>
                  <p>
                    <AnimationOnScroll
                      animateIn="bounceInUp"
                      animateOnce={true}
                      animatePreScroll={false}
                      duration={3}
                    >
                      <Trans
                        i18nKey="events.wedding.description"
                        components={{ br: <br /> }}
                      />
                    </AnimationOnScroll>
                  </p>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="eventContentBlock">
              <AnimationOnScroll
                animateIn="bounceInUp"
                animateOnce={true}
                animatePreScroll={false}
                duration={2}
              >
                <div className="eventContent eventContentRight">
                  <h3>
                    <Trans
                      i18nKey="events.corporate.title"
                      components={{ span: <span /> }}
                    />
                  </h3>
                  <p>
                    <Trans
                      i18nKey="events.corporate.description"
                      components={{ br: <br /> }}
                    />
                  </p>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="eventsBlockRight">
            <AnimationOnScroll
              animateIn="bounceInUp"
              animateOnce={true}
              animatePreScroll={false}
              duration={2}
            >
              <img className="imageContentRight" alt="image3" src={im3} />
            </AnimationOnScroll>
          </div>
        </div>
      }

      {['mobile', 'tablet'].includes(deviceType) && 
        <div className="eventsBlock">
          <div className="eventsBlockCenter">
            <div className="eventContentBlock">
              <div className="eventContent eventContentLeft">
                <h3>
                  <Trans
                    i18nKey="events.wedding.title"
                    components={{ span: <span /> }}
                  />
                </h3>
                <p>
                  <Trans
                    i18nKey="events.wedding.description"
                    components={{ br: <br /> }}
                  />
                </p>
              </div>
              <img className="imageContentCenter" alt='wedddings' src={weddings} />
            </div>
            <div className="eventContentBlock">
              <div className="eventContent eventContentLeft">
                <h3>
                  <Trans
                    i18nKey="events.corporate.title"
                    components={{ span: <span /> }}
                  />
                </h3>
                <p>
                  <Trans
                    i18nKey="events.corporate.description"
                    components={{ br: <br /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      }
      
      {!['mobile', 'tablet'].includes(deviceType) && (
        <AnimationOnScroll
          animateIn="bounceInUp"
          animateOnce={true}
          animatePreScroll={false}
          duration={1.5}
        >
          <div className="eventsBlock">
            <div className="eventsBlockForm">
              <img className="imageFormTop" alt="image4" src={im4} />
              <img className="imageFormCenter" alt="image5" src={im5} />
            </div>
            <div className="eventsBlockForm">
              <div className="eventContentForm">
                <h3>
                  <Trans
                    i18nKey="events.request.title"
                    components={{ span: <span /> }}
                  />
                </h3>
                <form
                  method="POST"
                  name="contactForm"
                  className="contactForm"
                  noValidate={true}
                  onSubmit={onSubmit}
                >
                  <div className="requestForm">
                    <input
                      type="name"
                      name="from_name"
                      placeholder={t("events.request.name")}
                      value={toSend.from_name}
                      className="smallName"
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder={t("events.request.email")}
                      value={toSend.email}
                      onChange={handleChange}
                    />
                    <input
                      type="phone"
                      name="contact"
                      placeholder={t("events.request.contact")}
                      value={toSend.contact}
                      onChange={handleChange}
                    />
                    <textarea
                      name="message"
                      cols="30"
                      rows="4"
                      placeholder={t("events.request.message")}
                      value={toSend.message}
                      onChange={handleChange}
                    />
                    <input
                      type="submit"
                      value={t("events.request.submit")}
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      )}

      {['mobile', 'tablet'].includes(deviceType) && (
        <div className="eventsBlock">
          <div className="eventsBlockForm">
            <div className="eventContentForm">
              <h3>
                <Trans
                  i18nKey="events.request.title"
                  components={{ span: <span /> }}
                />
              </h3>
              <form
                method="POST"
                name="contactForm"
                className="contactForm"
                noValidate={true}
                onSubmit={onSubmit}
              >
                <div className="requestForm">
                  <input
                    type="name"
                    name="from_name"
                    placeholder={t("events.request.name")}
                    value={toSend.from_name}
                    className="smallName"
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder={t("events.request.email")}
                    value={toSend.email}
                    onChange={handleChange}
                  />
                  <input
                    type="phone"
                    name="contact"
                    placeholder={t("events.request.contact")}
                    value={toSend.contact}
                    onChange={handleChange}
                  />
                  <textarea
                    name="message"
                    cols="30"
                    rows="4"
                    placeholder={t("events.request.message")}
                    value={toSend.message}
                    onChange={handleChange}
                  />
                  <input
                    type="submit"
                    value={t("events.request.submit")}
                    className="btn btn-primary"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <Booking />
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
    </div>
  );
}

export default Events;
