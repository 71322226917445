import React from "react";
import "./rooms.css";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import doble from "./images/doble.jpeg";
import doble_deluxe from "./images/double_deluxe.jpeg";
import apartment from "./images/apartment.jpg";
import apartment_deluxe from "./images/apartment_deluxe.jpeg";
import junior_suite from "./images/junior_suite.jpeg";
import suite_duplex from "./images/suite_duplex.jpeg";
import presedential_suite from "./images/presedential_suite.jpeg";

import Booking from "../Booking/Booking";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { deviceType } from "react-device-detect";

export function Rooms() {
  const { t } = useTranslation();

  return (
    <div className="flex-1 overflow-y-scroll roomsBody">
      <div className="roomsDesciptionContent">
        <p>
          <Trans
            i18nKey="rooms.description"
            defaults="18 personalised rooms with style, privacy and tranquility.<br />From the Standard Double Room to the Grand Deluxe Suite we are putting our best effort into emphasising every detail and history of its presence at Hotel Los Pilares de Ronda."
            components={{ br: <br /> }}
          />
        </p>
      </div>
      {['mobile', 'tablet'].includes(deviceType) && (
        <div className="roomsContentItemsBlock">
          <div
            className="roomsContentItem"
            key="doble"
            style={{ backgroundImage: `url(${doble})` }}
          >
            <NavLink to="/room_double" className="homeButton">
              {t("rooms.double")}
            </NavLink>
          </div>
          <div
            className="roomsContentItem"
            key="double_deluxe"
            style={{ backgroundImage: `url(${doble_deluxe})` }}
          >
            <NavLink to="/room_double_deluxe" className="homeButton">
              {t("rooms.double_deluxe")}
            </NavLink>
          </div>
          <div
            className="roomsContentItem"
            key="apartment"
            style={{ backgroundImage: `url(${apartment})` }}
          >
            <NavLink to="/room_apartament" className="homeButton">
              {t("rooms.apartment")}
            </NavLink>
          </div>
          <div
            className="roomsContentItem"
            key="apartment_deluxe"
            style={{ backgroundImage: `url(${apartment_deluxe})` }}
          >
            <NavLink to="/room_apartament_deluxe" className="homeButton">
              {t("rooms.apartment_deluxe")}
            </NavLink>
          </div>
          <div
            className="roomsContentItem"
            key="junior_suite"
            style={{ backgroundImage: `url(${junior_suite})` }}
          >
            <NavLink to="/room_junior_suite" className="homeButton">
              {t("rooms.junior_suite")}
            </NavLink>
          </div>
          <div
            className="roomsContentItem"
            key="suite_duplex"
            style={{ backgroundImage: `url(${suite_duplex})` }}
          >
            <NavLink to="/room_suite_deplex" className="homeButton">
              {t("rooms.suite_duplex")}
            </NavLink>
          </div>
          <div
            className="roomsContentItem"
            key="presedential_suite"
            style={{ backgroundImage: `url(${presedential_suite})` }}
          >
            <NavLink to="/room_presidental_suite" className="homeButton">
              {t("rooms.presedential_suite")}
            </NavLink>
          </div>
        </div>
      )}
      {!['mobile', 'tablet'].includes(deviceType) && (
        <div className="roomsContentItemsBlock">
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            animatePreScroll={false}
            duration={1.5}
          >
            <div
              className="roomsContentItem"
              key="doble"
              style={{ backgroundImage: `url(${doble})` }}
            >
              <NavLink to="/room_double" className="homeButton">
                {t("rooms.double")}
              </NavLink>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            animatePreScroll={false}
            duration={2}
          >
            <div
              className="roomsContentItem"
              key="double_deluxe"
              style={{ backgroundImage: `url(${doble_deluxe})` }}
            >
              <NavLink to="/room_double_deluxe" className="homeButton">
                {t("rooms.double_deluxe")}
              </NavLink>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={2.8}
          >
            <div
              className="roomsContentItem"
              key="apartment"
              style={{ backgroundImage: `url(${apartment})` }}
            >
              <NavLink to="/room_apartament" className="homeButton">
                {t("rooms.apartment")}
              </NavLink>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={3.2}
          >
            <div
              className="roomsContentItem"
              key="apartment_deluxe"
              style={{ backgroundImage: `url(${apartment_deluxe})` }}
            >
              <NavLink to="/room_apartament_deluxe" className="homeButton">
                {t("rooms.apartment_deluxe")}
              </NavLink>
            </div>
          </AnimationOnScroll>
        </div>
      )}

      {!['mobile', 'tablet'].includes(deviceType) && (
        <div className="roomsContentItemsBlockBottom">
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={1.8}
          >
            <div
              className="roomsContentItem"
              key="junior_suite"
              style={{ backgroundImage: `url(${junior_suite})` }}
            >
              <NavLink to="/room_junior_suite" className="homeButton">
                {t("rooms.junior_suite")}
              </NavLink>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={2.2}
          >
            <div
              className="roomsContentItem"
              key="suite_duplex"
              style={{ backgroundImage: `url(${suite_duplex})` }}
            >
              <NavLink to="/room_suite_deplex" className="homeButton">
                {t("rooms.suite_duplex")}
              </NavLink>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="bounceInUp"
            animateOnce={true}
            duration={2.4}
          >
            <div
              className="roomsContentItem"
              key="presedential_suite"
              style={{ backgroundImage: `url(${presedential_suite})` }}
            >
              <NavLink to="/room_presidental_suite" className="homeButton">
                {t("rooms.presedential_suite")}
              </NavLink>
            </div>
          </AnimationOnScroll>
        </div>
      )}
      <Booking />
    </div>
  );
}

export default Rooms;
