import React from "react";
import './header.css'
import logo from './SmallLogo.svg';
import star from './star-full.png';

class Logo extends React.Component {
  render() {
    return (
      <a href="#">
        <div className="logoBlock">
          <img className="logo" src={logo} alt="logo" />
          <h1 className="logoTitle">Boutique Hotel</h1>
          <h1  className="logoTitle">Los Pilares De Ronda</h1>
          <div className="stars">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
          </div>
        </div>
      </a>
    );
  }
}
export default Logo;