import React from 'react';
import './App.css';
import "./animate.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Gallery from './components/Gallery/Gallery';
import Gastro from './components/Gastro/Gastro';
import Activities from './components/Activities/Activities';
import Rooms from './components/Rooms/ Rooms';
import Double from './components/Rooms/rooms/Double';
import DoubleDeluxe from './components/Rooms/rooms/DoubleDeluxe';
import Apartament from './components/Rooms/rooms/Apartament';
import ApartamentDeluxe from './components/Rooms/rooms/ApartamentDeluxe';
import JuniorSuite from './components/Rooms/rooms/JuniorSuite';
import SuiteDuplex from './components/Rooms/rooms/SuiteDuplex';
import PresidentialSuite from './components/Rooms/rooms/PresidentialSuite';
import Events from './components/Events/Events';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <Router>
      <div className="flex flex-col h-screen">
        <Header />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/gastro" exact component={Gastro} />
          <Route path="/events" exact component={Events} />
          <Route path="/activities" exact component={Activities} />
          <Route path="/rooms" exact component={Rooms} />
          <Route path="/room_double" exact component={Double} />
          <Route path="/room_double_deluxe" exact component={DoubleDeluxe} />
          <Route path="/room_apartament" exact component={Apartament} />
          <Route path="/room_apartament_deluxe" exact component={ApartamentDeluxe} />
          <Route path="/room_junior_suite" exact component={JuniorSuite} />
          <Route path="/room_suite_deplex" exact component={SuiteDuplex} />
          <Route path="/room_presidental_suite" exact component={PresidentialSuite} />
        </Switch>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
