import React from "react";
import "./modal.css";
import { useTranslation } from 'react-i18next';

export default function CustomModal({ setOpenModal }) {

  const { t } = useTranslation();

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>
          {t("events.dialog.title")}
          </h1>
        </div>
        <div className="body" id="body">
            {t("events.dialog.message")}
        </div>
        <div className="footer">
          {/* <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button> */}
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="continueBtn"
          >
            {t("events.dialog.continue")}
          </button>
        </div>
      </div>
    </div>
  );
}